//get user location

export default async function GetUserLocation() {
    return new Promise((resolve, reject) => {
        if(!("geolocation" in navigator)) {
            reject(new Error('Geolocation is not available.'));
        }
        navigator.geolocation.getCurrentPosition(position => {
            var lat = position.coords.latitude;
            var long = position.coords.longitude;
            var locArray = [lat,long]
            resolve(locArray);
        }, err => {
            reject(err);
        });

    });
}
