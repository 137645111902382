//Function to get DU-PL Rating
export default function GetRateAverage(rates,fourSquare,priceTier,address){

    var trueRating
    var rateSum = 0;
    for(var i = 0; i < rates.length; i++) {
        rateSum += parseInt(rates[i]);
    }
    var rateAvg = rateSum / rates.length;

    if(rates.length === 0){
        if(fourSquare > 0){
            rateAvg=fourSquare
        }
    }

    trueRating = (rateAvg +(fourSquare-1))/(2.1);

    if(priceTier > 0){
        var weight = 1/Math.log10(priceTier+6);
        trueRating = weight*trueRating;
        if(trueRating > 10){
            trueRating = 10;
        }
    }

    return(trueRating)
}