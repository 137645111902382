import React from 'react';
import Typography from "@material-ui/core/Typography";
import CalculatorForm from "./CalculatorForm";

class CalculatorPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render(){
        return (
            <div className='homepage'>
                <Typography variant={'h4'} color='textPrimary' className='slogan' >
                    DU-PL BAC Calculator
                </Typography>
                <CalculatorForm/>
                <div className="disclaimer">
                    This BAC estimate calculator and its results is NOT any form of legal advice, does NOT replace any
                    medical advice of your health care provider, and should NOT be relied upon in any way. No BAC
                    calculator has 100% accuracy due to the many factors that make up one's BAC.
                </div>
                <div>
                    DO NOT DRINK AND DRIVE
                </div>
                {/*
                <div className="disclaimer">
                    Sources: Maudens KE, Patteet L, Van nuijs AL, Van broekhoven C, Covaci A, Neels H.
                    The influence of the body mass index (BMI) on the volume of distribution of ethanol.
                    Forensic Sci Int. 2014;243:74-8.
                </div>
                */}
            </div>
        );
    }
}

export default CalculatorPage