import axios from 'axios';

function BarSearch(searchTerm, lat, long) {
    const endPoint = 'https://api.foursquare.com/v2/venues/search?';
    const params = {
        client_id: '5SHXBDE5CEWM0SWVFVAV0GUCHTSJ2WTWZIDT2Y31KFMS2WMN',
        client_secret: '4HIP5Y1V2UZFW5AMB2FZ25X2JAPBQUCHYRJUSOBGB5CVN545',
        ll: lat + ',' + long,
        categoryId: '4bf58dd8d48988d116941735,4bf58dd8d48988d11b941735,4bf58dd8d48988d11d941735,50327c8591d4c4b30a586d5d', //bars,beer bar,sports bar,brewery
        query: searchTerm,
        intent: 'checkin',
        limit: '20',
        radius: '40000', //in meters
        v: '20180323'
    };
    return axios.get(endPoint + new URLSearchParams(params), {timeout: 5000}).then((response)=>{
        let venues = response.data.response.venues;
        var dataArray = [];
        var firstDup = {name: ''};
        for (var i = 0; i < venues.length; i++) {
            if (venues[i].location.address) {
                if(dataArray.find(x => x.name === venues[i].name) !== undefined){
                    firstDup = {name: venues[i].name, location: ": " + venues[i].location.city}
                    dataArray.push({
                        name: venues[i].name + ": " + venues[i].location.city,
                        id: venues[i].id,
                    })
                }
                else{
                    dataArray.push({
                        name: venues[i].name,
                        id: venues[i].id,
                        location: venues[i].location.city
                    })
                }
            }
        }

        var correctedArray = dataArray.map((item) => {
            if (item.name === firstDup.name) {
                return {name: item.name + ": " + item.location, id: item.id}
            }
            else{
                return {name: item.name, id: item.id}
            }
        })
        return Promise.all(correctedArray)
    }).catch(error =>{
        console.log(error)
    })
}

export default BarSearch