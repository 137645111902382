import React from 'react';
import {BrowserRouter as Router, Route} from "react-router-dom";
import './App.css';
import { createMuiTheme } from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/core/styles';
import HeaderBar from "./components/HeaderBar";
import SearchBar from "./components/Search/SearchBar";
import CalculatorPage from "./components/BacCalc/CalculatorPage"
import MyFavorites from "./components/UserFavorites/MyFavorites";
import EventPage from "./components/Events/EventPage"

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#43626B'},
        secondary: {
            main: '#696C6D',
        },
        background: {
            default: "#CDD1DC"
        },
        text:{
            primary: "#1D1E18",
            secondary: "#1D1E18"
        }
    },
    typography: {
        fontSize: 12,
    }
});


class App extends React.Component {

    componentDidMount() {
        //Purge temp cache when site is loaded and cache is expired
        caches.open("temp").then(
            cache => cache.keys().then(
                keys => keys.forEach(
                    // Loop over all requests stored in the cache and get the
                    // matching cached response.
                    key => cache.match(key).then((cachedResponse) => {
                        // Check expiration and eventually delete the cached
                        // item
                        const expirationDate = Date.parse(cachedResponse.headers.get('sw-cache-expires'));
                        const now = new Date();
                        if (expirationDate < now) {
                            cache.delete(key);
                        }
                    }),
                ),
            ),
        );
    }

    render(){
        return (
            <ThemeProvider theme={theme}>
                <div className="App">
                    <Router>
                        <HeaderBar/>
                        <Route exact path="/" component={SearchBar} />
                        <Route exact path="/bar/:urlid/" component={SearchBar} />
                        <Route exact path="/saved" component={MyFavorites} />
                        <Route exact path="/saved/:urlid/" component={MyFavorites} />
                        <Route exact path="/calculator" component={CalculatorPage} />
                        <Route exact path="/events" component={EventPage} />
                    </Router>
                </div>
            </ThemeProvider>
        );
    }
}

export default App;
