/* eslint-disable no-use-before-define */
import React from 'react';
import Button from '@material-ui/core/Button';
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import SubmitData from './SubmitData'
import Dialog from "@material-ui/core/Dialog";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import DrinkAutocomplete from "./DrinkAutocomplete";
import Autocomplete from '@material-ui/lab/Autocomplete';
import SizeAutoComplete from "./SizeAutocomplete";
import SubmitSuccess from "./SubmitSuccess";
import SubmitIncomplete from "./SubmitIncomplete";
import InputAdornment from "@material-ui/core/InputAdornment";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import SubmitVerify from "./SubmitVerify";
import SubmitFailure from "./SubmitFailure";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

const theme = createMuiTheme({
    shape: {
        borderRadius: 10
    },
    palette: {
        primary: {
            main: '#696C6D'},
        secondary: {
            main: '#17e294',
        },
    },
});

const style = {
    margin: 0,
    top: 'auto',
    right: 30,
    bottom: 25,
    left: 'auto',
    position: 'fixed',
};

const ratings = [
    {
        value: 1,
        label: '1',
    },
    {
        value: 5.5,
        label: '5.5',
    },
    {
        value: 10,
        label: '10',
    },
];


const drinkNames = DrinkAutocomplete();
const sizes = SizeAutoComplete();


class Submission extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            name: '',
            size: '',
            price: '',
            rate: 5.5,
            submitMessage: <div/>,
            verifyDrink: <div/>
        }
    }

    handleClickOpen = () => {
        this.setState({open:true})
    };

    handleClose = () => {
       this.setState({open: false})
    };

    handleName = () => event => {
        this.setState({name: event.target.value})
    };

    handleSize = () => event => {
        this.setState({size: event.target.value})
    };

    handlePrice = () => event => {
        this.setState({price: event.target.value})
    };

    handleSubmitClick = async () => {
        this.handleClose();
        this.setState({
            submitMessage: <div/>,
    }, async () =>{
            var response = await this.waitForSubmit();
            if(response.status === 200){
                this.setState({
                    submitMessage: <SubmitSuccess/>,
                    name: '',
                    size: '',
                    price: '',
                    rate: '5.5'
                })
            }
            if(response === "invalidName"){
                this.setState({submitMessage: <SubmitIncomplete invalid={"Name"}/>})
            }
            if(response === "invalidPrice"){
                this.setState({submitMessage: <SubmitIncomplete invalid={"Price"}/>})
            }
            if(response === "invalidRating"){
                this.setState({submitMessage: <SubmitIncomplete invalid={"Rating"}/>})
            }
            if(response === "error"){
                this.setState({submitMessage: <SubmitFailure/>})
            }
            if(response.status === 500){
                this.setState({verifyDrink:
                        <SubmitVerify open={true} id={this.props.barID} name={this.state.name}
                                      price={this.state.price} rate={this.state.rate} size={this.state.size}
                        />
                })
                this.setState({
                    name: '',
                    size: '',
                    price: '',
                    rate: '5.5'
                })
            }
        })
    }

    waitForSubmit = async () => {
        var response = await SubmitData("new",this.props.barID,this.state.name,this.state.size,this.state.price,this.state.rate)
        return (response)
        }

    render(){
        return(
            <ThemeProvider theme={theme}>
                <div className='submit'>
                    <Fab color="secondary" aria-label="add drink" style={style} onClick={this.handleClickOpen}>
                        <AddIcon />
                    </Fab>
                </div><Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="submission form">
                <div className='altBackground'>
                    <DialogTitle id="form-dialog-title" className='submitTitle'>
                        Submit New Drink
                    </DialogTitle>
                    <DialogContent>
                        <Autocomplete id='newDrinkName'
                                      className='submit'
                                      aria-labelledby="drink name input"
                                      style={{ width: 200 }}
                                      options={drinkNames}
                                      autoSelect={true}
                                      blurOnSelect={true}
                                      freeSolo={true}
                                      value={this.state.name}
                                      onChange={this.handleName()}
                                      renderInput={params => (
                                          <TextField {...params} required label="Drink Name" variant="outlined"  fullWidth />
                                      )}
                        />
                        <div className="submit">
                            <FormControl variant="outlined" style={{margin: theme.spacing(1),
                                minWidth: 120,}}>
                                <InputLabel id="label">Size</InputLabel>
                                <Select labelId="label" id="newDrinkSize" className="size" aria-labelledby="drink size input"
                                        value={this.state.size} notched={true} onChange={this.handleSize()}
                                >
                                    {sizes.map(size => {
                                        return <MenuItem key={size} value={size}>{size}</MenuItem>;
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                        <div className='submit'>
                            <TextField required id="newDrinkPrice" style={{ width: 115 }}
                                       type="number" variant="outlined" label="Price" aria-labelledby="price input"
                                       value={this.state.price}
                                       onChange={this.handlePrice()}
                                       placeholder={"0.00"} min={0} max={100}
                                       InputProps={{
                                           startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                       }}
                            />
                        </div>
                        <div className='submit'>
                            <Typography>Rating*</Typography>
                            <Slider
                                id="newDrinkRate"
                                value={Number(this.state.rate)}
                                aria-labelledby="rating-slider"
                                step={0.5}
                                marks={ratings}
                                valueLabelDisplay="auto"
                                min={1}
                                max={10}
                                onChange={(event, value)=>
                                    this.setState({rate: value})}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <div className='input'>
                            <Button
                                variant="contained"
                                aria-labelledby="submit button"
                                color="primary"
                                startIcon={<CloudUploadIcon/>}
                                onClick={this.handleSubmitClick}
                            >Submit
                            </Button>
                        </div>
                    </DialogActions>
                </div>
            </Dialog>
                {this.state.submitMessage}
                {this.state.verifyDrink}
            </ThemeProvider>
        )
    }
}

export default Submission