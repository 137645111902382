import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import DirectionsIcon from '@material-ui/icons/Directions';

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(0),
    },
    typography: {
        padding: theme.spacing(2),
    },
}));

export default function ShareBarButton(props) {
    //apple maps link passes as google link in google devices/chrome
    var endpoint = 'https://maps.apple.com/?daddr=';
    var passedAddress = String(props.barAddress);
    var unformattedAddress = passedAddress.replace(/ *\([^)]*\) */g, "");
    var removespaces = unformattedAddress.replace(/ /g,"+");
    //For Google: var removecommas = removespaces.replace(/,/g,"%2C");
    // for google var destination = 'destination=' + removecommas;
    var destination = removespaces;
    var url = endpoint + destination;

    const classes = useStyles();

    const handleClick = event => {
        window.location.href=url;
    };

    return (
        <div className='inner'>
            <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                startIcon={<DirectionsIcon/>}
                onClick={() => {handleClick()}}
            >
                Directions
            </Button>
        </div>
    )
}
