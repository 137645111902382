import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import BarSearch from "./BarSearch";
import Button from "@material-ui/core/Button";
import MenuBookIcon from '@material-ui/icons/MenuBook';
import {createMuiTheme} from "@material-ui/core/styles";
import {ThemeProvider} from '@material-ui/core/styles';
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import NoSelectionError from "./NoSelectionError";
import DetailsMenu from "../Menu/DetailsMenu";
import { Redirect} from "react-router-dom";
import GetUserLocation from "./GetLocation";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#696C6D'},
        secondary: {
            main: '#43626B',
        },
        text:{
            primary: "#000000",
            secondary: "#000000"
        },
    },
    overrides: {
        MuiAutocomplete: {
            root:{
                width: "100%",
                borderRadius: 8,
            },
            inputRoot:{
                height: 58,
                borderRadius: 8,
            },
            option:{
                color: "#000000",
            },
            loading:{
                value: "Keep typing.."
            },
            noOptions: {
                value: "No results found"
            },
        },
        MuiTextField: {
            // Name of the rule
            root: {
                // Some CSS
                background: 'white',
                borderRadius: 8,
                height: 58,
                width: "100%",
                boxShadow: '0 3px 6px 3px rgba(255, 105, 135, .3)',
            },
        },
    },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} navigate={"/"}/>

});


export default class SearchBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            options: [],
            openMenu: false,
            searchTerm: '',
            dataArray: [],
            idSelected: '',
            noBarError: <div/>,
            goHelper: '',
            menuRedirect: <div/>,
            welcomeMessage: 'Start Typing a Bar\'s Name to find Drink Prices',
        }
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    handleChange (e) {
        this.setState({searchTerm: e.target.value});
        BarSearch(this.state.searchTerm,this.state.lat,this.state.long).then(results => {
            this.setState({options: results})
        })
    }

    handleSelection(){
        var selectedBar = document.getElementById('asynchronous-search').value;
        var menuUrl = "/bar/" + String(this.state.options.find(({ name }) => name === selectedBar).id);
        if(selectedBar){
            this.setState({
                idSelected: this.state.options.find(({ name }) => name === selectedBar).id,
                goHelper: '',
                openMenu: true,
                menuRedirect: <Redirect to={menuUrl} />
            })
        }
        else{
            this.setState({noBarError:  <NoSelectionError/>});
            setTimeout(() => {this.setState({noBarError:  <div/>})},2600);
        }
    }

    async handleOpen(){
        var locArray = await GetUserLocation();
        this.setState({lat: locArray[0],long:locArray[1], open: true, goHelper: ''});
        BarSearch(this.state.searchTerm,this.state.lat,this.state.long).then(results => {
            this.setState({options: results})
        })
    }

    handleClose() {
        this.setState({openMenu: false});
    };

    handleKeyPress(e) {
        var key = e.keyCode || e.which;
        if (key === 13) {
            this.handleSelection()
        }
    }

    componentDidMount() {
        if(this.props.match.params.urlid){
            this.setState({idSelected: this.props.match.params.urlid});
            this.setState({openMenu: true})
        }
    }

    endTransition(){
        this.setState({menuRedirect: <Redirect to={"/"}/>})
    }

    render(){
        const loading = this.state.open;

        return (
            <div className='homepage'>
                <Typography variant={'h4'} color='textPrimary' className='slogan' >
                    Drink Up, Pay Less!
                </Typography>
                <Typography variant='h6' className={'welcome'}>
                    {this.state.welcomeMessage}
                </Typography>
                <ThemeProvider theme={theme}>
                    <div className='outer'>
                        <Autocomplete
                            id="asynchronous-search"
                            className='search'
                            ///color='textPrimary'
                            style={{ width: 300 }}
                            open={this.state.open}
                            onOpen={() => { this.handleOpen()
                            }}
                            onClose={() => {
                                this.setState({open: false,
                                    goHelper: 'goHelper'
                                });
                            }}
                            getOptionSelected={(option, value) => option.name === value.name}
                            getOptionLabel={option => option.name}
                            options={this.state.options}
                            loading={loading}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    className='search'
                                    id='outlined-basic'
                                    label="Search for a Bar"
                                    variant="filled"
                                    onChange={(event) => {this.handleChange(event)}}
                                    onKeyPress={this.handleKeyPress}
                                    value={this.state.searchTerm}
                                    InputProps={{
                                        ...params.InputProps,
                                        disableUnderline: true,
                                        endAdornment: (
                                            <React.Fragment>
                                                {loading ? <CircularProgress color="secondary" size={22} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />
                        <Button className={this.state.goHelper} aria-label="Open Menu" variant="contained" color="primary" onClick={()=>{this.handleSelection()}}>
                            <MenuBookIcon/>
                        </Button>
                    </div>
                    {this.state.noBarError}
                    {this.state.menuRedirect}
                    <Dialog fullScreen open={this.state.openMenu} TransitionComponent={Transition} onExited={()=> this.endTransition()}>
                        <AppBar position='relative'>
                            <Toolbar>
                                <IconButton edge="start" color="inherit" onClick={()=>{this.handleClose()}} aria-label="close">
                                    <CloseIcon />
                                </IconButton>
                                <Typography variant="h6" marginleft='theme.spacing(2)' flex='1'>
                                    Venue Menu
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        <DetailsMenu barID={this.state.idSelected}/>
                    </Dialog>
                </ThemeProvider>
            </div>
        );

    }
}