import React from 'react';
import Typography from "@material-ui/core/Typography";

class EventPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render(){
        return (
            <div className='homepage'>
                <Typography variant={'h4'} color='textPrimary' className='slogan' >
                    DU-PL Event Finder
                </Typography>
                <div>
                    Coming Soon!
                </div>
            </div>
        );
    }
}

export default EventPage