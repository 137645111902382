//Page to display a user's saved bars from the cache
import React from "react";
import Typography from "@material-ui/core/Typography";
import MakeList from "./MakeList";
import Slide from "@material-ui/core/Slide";
import DetailsMenu from "../Menu/DetailsMenu";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import {Redirect} from "react-router-dom";
import {createMuiTheme} from "@material-ui/core/styles";
import {ThemeProvider} from "@material-ui/core/styles";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#696C6D'},
        secondary: {
            main: '#43626B',
        },
        text:{
            primary: "#000000",
            secondary: "#000000"
        }
    }
});


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default class MyFavorites extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            infoArray: [],
            savedList: <div/>,
            idSelected: '',
            openMenu: false,
            menuRedirect: <div/>,
        }
    }

    componentDidMount() {
        if(this.props.match.params.urlid){
            this.setState({idSelected: this.props.match.params.urlid});
            this.setState({openMenu: true})
        }

        this.grabCache()
    }

    handleClose() {
        this.setState({openMenu: false, menuRedirect: <div/>});
    };

    async grabCache() {
        caches.open("Bar Names").then((cache)=>{
            if(cache !== undefined){
                var idArray = [];
                var nameArray = [];
                var infoArray = [];
                cache.matchAll().then(async (response)=>{
                    for(var i=0; i < response.length; i++){
                        await response[i].json().then((res) => {
                            var id = res.response.venue.id;
                            var name = res.response.venue.name;
                            idArray.push(id);
                            nameArray.push(name);
                            infoArray.push({id: id, name: name})
                        })
                    }
                    return infoArray
                }).then((res) => {
                    this.renderSaved(res);
                })
            }
        })
    }

    renderSaved(infoArray){
        this.setState({savedList: <MakeList infoArray={infoArray}/>})
    }

    endTransition(){
        this.setState({menuRedirect: <Redirect to={"/saved"}/>})
    }

    render(){
        return(
            <ThemeProvider theme={theme}>
                <div className='homepage'>
                    <Typography variant={'h4'} color='textPrimary' className='slogan' >
                        My Saved Bars
                    </Typography>
                    <div>
                        {this.state.savedList}
                    </div>
                    {this.state.menuRedirect}
                    <Dialog fullScreen open={this.state.openMenu} TransitionComponent={Transition} onExited={()=> this.endTransition()}>
                        <AppBar position='relative'>
                            <Toolbar>
                                <IconButton edge="start" color="inherit" onClick={()=>{this.handleClose()}} aria-label="close">
                                    <CloseIcon />
                                </IconButton>
                                <Typography variant="h6" marginleft='theme.spacing(2)' flex='1'>
                                    Venue Menu
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        <DetailsMenu barID={this.state.idSelected}/>
                    </Dialog>
                </div>
            </ThemeProvider>
        )
    }
}