import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import Calculate from "./Calculate";
import {RadioGroup} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Typography from "@material-ui/core/Typography";
import CalculateError from "./CalculateError";
import Radio from "@material-ui/core/Radio";

export default class CalculatorForm extends React.Component{
    constructor(props) {
        super(props);
        this.state ={
            bac: <div/>,
            gender: 'male',
            stomach: 'full'
        }
    }

    handleGender = (event) => {
        this.setState({gender: event.target.value})
    }

    handleStomach(event){
        console.log(event)
        this.setState({stomach: event.target.value})
    }

    handleClick(){
        this.setState({bac: <div/>})
        var gender = this.state.gender;
        var stomach = this.state.stomach;
        var heightft = parseFloat(document.getElementById('heightft').value);
        var heightin = parseFloat(document.getElementById('heightin').value);
        if(isNaN(heightin)){ heightin = 0}
        var weight = parseFloat(document.getElementById('weight').value);
        var time = parseFloat(document.getElementById('time').value);
        if(isNaN(time)){ time = 0}
        var beerVol = 0.045 * 16 * parseFloat(document.getElementById('beer').value);
        var wineVol = 0.116 * 8 * parseFloat(document.getElementById('wine').value);
        var liquorVol = 0.38 * 1.5 * parseFloat(document.getElementById('liquor').value);
        if(isNaN(beerVol)){ beerVol = 0}
        if(isNaN(wineVol)){ wineVol = 0}
        if(isNaN(liquorVol)){ liquorVol = 0}
        var alcoholVol = parseFloat(beerVol) + parseFloat(wineVol) + parseFloat(liquorVol);
        var height = (heightft*12) + heightin
        var valArray = [gender,stomach,height,weight,alcoholVol,time]
        var calcResult = parseFloat(Calculate(valArray))
        if(isNaN(calcResult) || calcResult < 0){
            this.setState({
                bac: <CalculateError/>
            })
        }
        else{
            this.setState({
                bac:
                    <Typography className='calcResult' variant="h5">
                        Your Estimated BAC is {calcResult}
                    </Typography>
            })
        }
    }

    render(){

        return (
            <div className="calcForm">
                <form>
                    <FormControl component="fieldset" >
                        <FormLabel component="legend">Gender</FormLabel>
                        <RadioGroup aria-label="gender" name="gender" value={this.state.gender}
                                    onChange={(event)=>{this.handleGender(event)}}
                        >
                                <FormControlLabel value="male" style={{margin: "auto"}} control={<Radio/>} label="Male"/>
                                <FormControlLabel value="female" style={{margin: "auto"}} control={<Radio/>} label="Female"/>
                        </RadioGroup>
                    </FormControl>
                    <FormControl component="fieldset" >
                        <FormLabel component="legend">Stomach</FormLabel>
                        <RadioGroup aria-label="stomach" name="stomach" value={this.state.stomach}
                                    onChange={(event)=>{this.handleStomach(event)}}
                        >
                                <FormControlLabel value="full" style={{margin: "auto"}} control={<Radio/>} label="Full"/>
                                <FormControlLabel value="empty" style={{margin: "auto"}} control={<Radio/>} label="Empty" />
                        </RadioGroup>
                    </FormControl>
                    <div>
                        <TextField required type="number" style={{ width: 100, margin: "3%",}} className="calcField" variant="outlined" id="heightft" label="Height (ft)" />
                        <TextField type="number" style={{ width: 100, margin: "3%",}} className="calcField" variant="outlined" id="heightin" label="Height (in)" />
                        <TextField type="number" required style={{ width: 120, margin: "3%",}} className="calcField" variant="outlined" id="weight" label="Weight (lbs)" />
                    </div>
                    <div>
                        <TextField id="time" type="number" style={{ width: 130, margin: "3%" }} variant="outlined" label="Hours Drinking" />
                    </div>
                    <div>
                        <TextField style={{ width: 100, margin: "3%" }} type="number" variant="outlined" id="beer" label="Beer (16oz)" />
                        <TextField style={{ width: 100, margin: "3%" }} type="number" variant="outlined" id="wine" label="Wine (8oz)" />
                        <TextField style={{ width: 115, margin: "3%" }} type="number" variant="outlined" id="liquor" label="Liquor (1.5oz)" />
                    </div>
                    <div className="calcButton">
                        <Button variant="contained" color="primary" onClick={() => {this.handleClick()}}> GO </Button>
                    </div>
                </form>
                {this.state.bac}
            </div>
        );
    }

}