import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SearchIcon from '@material-ui/icons/Search';
import MenuIcon from '@material-ui/icons/Menu';
import InfoIcon from '@material-ui/icons/Info';
import Dialog from "@material-ui/core/Dialog";
import AboutPage from "./AboutPage";
import LocalDrinkIcon from '@material-ui/icons/LocalDrink';
import { NavLink } from "react-router-dom";
import FavoriteIcon from '@material-ui/icons/Favorite';
import EventIcon from '@material-ui/icons/Event';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
    },
    list: {
        width: "50%",
        color: '#000000',
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function HeaderBar() {
    const classes = useStyles();
    const [openAbout, setOpenAbout] = React.useState(false);
    const [state, setState] = React.useState({
       left: false,
       openAbout: false,
    });

    const toggleDrawer = (side, open) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({...state, [side]: open});
    }

    const sideList = side => (
        <div
            className={classes.list}
            role="presentation"
            onClick={toggleDrawer(side, false)}
            onKeyDown={toggleDrawer(side, false)}
        >
            <List>
                <ListItem button>
                    <ListItemIcon><SearchIcon/></ListItemIcon>
                    <NavLink className="sidebarLink" to="/">Bar Search</NavLink>
                </ListItem>
                <ListItem button>
                    <ListItemIcon><FavoriteIcon/></ListItemIcon>
                    <NavLink className="sidebarLink" to="/saved">Saved Bars</NavLink>
                </ListItem>
                <ListItem button>
                    <ListItemIcon><LocalDrinkIcon/></ListItemIcon>
                    <NavLink className="sidebarLink" to="/calculator">BAC Calculator</NavLink>
                </ListItem>
                <ListItem button>
                    <ListItemIcon><EventIcon/></ListItemIcon>
                    <NavLink className="sidebarLink" to="/events">Nearby Events</NavLink>
                </ListItem>
                <ListItem button onClick={()=>{setOpenAbout(true)}}>
                    <ListItemIcon><InfoIcon/></ListItemIcon>
                    <ListItemText primary='About'/>
                </ListItem>
            </List>
        </div>
    )

    const handleClose = () => {
        setOpenAbout(false);
    };

        return (
            <div className={classes.root}>
                <AppBar position="relative">
                    <Toolbar>
                        <IconButton className={classes.menuButton} color="inherit" aria-label="menu"
                                    onClick={toggleDrawer('left', true)}>
                            <MenuIcon/>
                        </IconButton>
                        <Drawer open={state.left} onClose={toggleDrawer('left', false)}>
                            {sideList('left')}
                        </Drawer>
                        <img align='inherit' className='logo' src={require("../img/logo.png")} alt="DU-PL"/>
                    </Toolbar>
                </AppBar>
                <div className='about'>
                    <Dialog open={openAbout} onClose={handleClose} aria-label="about-dialog">
                        <AboutPage/>
                    </Dialog>
                </div>
            </div>
        );
}