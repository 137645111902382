//function to calculate bac with passed props from the form

export default function Calculate(valArray){
    var gender=valArray[0];
    var stomach = valArray[1];
    var height = valArray[2];
    var weight = valArray[3];
    var alcoholOz = valArray[4];
    var time = valArray[5];

    var alcoholMl = 29.5735 * alcoholOz;
    var alcoholMassG =  0.789 * alcoholMl;
    var A = alcoholMassG;

    var weightKg = weight / 2.2046;
    var heightM = height / 39.37;
    var bmi =  weightKg/(heightM*heightM);

    var r; //weight distribution coefficient
    if(gender === "female"){
        r =  0.7772 - (0.0099 * bmi);
    }
    else{
        r = 0.8202-(0.0090 * bmi);
    }
    var W = weightKg * 10;
    //var k = time-1; Rate of alcohol consumption (h-1)
    var beta; //alcohol elimination rate (g/L)/h
    if(stomach === "empty"){
        beta = 0.011 //0.015 g/100mL/hour
    }
    else{
        beta = 0.015 // g/100mL/hour
    }
    var bacResult = (A/(r*W)) - (beta*time);
    //var bacResult = (A* (1 - Math.exp((-k*time)))/(r*W))-(beta*time);

    return(bacResult.toFixed(3))
}