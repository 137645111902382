import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import ShareIcon from '@material-ui/icons/Share';
import MuiAlert from '@material-ui/lab/Alert';
import copy from 'clipboard-copy'
import Snackbar from "@material-ui/core/Snackbar";

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(2),
    },
    typography: {
        padding: theme.spacing(2),
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ShareBarButton(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    var barURL = String("http://du-pl.com/bar/" + props.barID);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleClick = event => {
        var successPromise = copy(barURL);
        console.log(successPromise);
        setOpen(true);
        if (successPromise){
            setOpen(true)
        }
    };

    return (
        <div className='inner'>
            {document.queryCommandSupported('copy') &&
            <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                startIcon={<ShareIcon/>}
                onClick={() => {handleClick()}}
            >
                Share
            </Button>
            }
            <div className={classes.root}>
                <Snackbar open={open} autoHideDuration={2500} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success">
                        Copied Link!
                    </Alert>
                </Snackbar>
            </div>
        </div>
    )
}
