//List of Possible Drink Sizes

export default function SizeAutoComplete(){
    const sizes = [
        "",
        "Shot",
        "Double",
        "Cocktail",
        "Bottle",
        "Can",
        "Pint",
        "Liter"
    ]

    return (sizes);
}