import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import CheckIcon from '@material-ui/icons/Check';
import Dialog from "@material-ui/core/Dialog";
import React from "react";
import SubmitSuccess from "./SubmitSuccess";
import SubmitIncomplete from "./SubmitIncomplete";
import SubmitData from "./SubmitData";
import SubmitFailure from "./SubmitFailure";
import {createMuiTheme} from "@material-ui/core/styles";
import {ThemeProvider} from "@material-ui/core/styles";

const theme = createMuiTheme({
    shape: {
        borderRadius: 10
    },
    palette: {
        primary: {
            main: '#696C6D'},
        secondary: {
            main: '#17e294',
        },
    },
});

const ratings = [
    {
        value: 1,
        label: '1',
    },
    {
        value: 5.5,
        label: '5.5',
    },
    {
        value: 10,
        label: '10',
    },
];

export default class SubmitVerify extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            open: this.props.open,
            name: this.props.name.substring(0, 1).toUpperCase() + this.props.name.substring(1),
            rate: this.props.rate
        }
    }

    handleClose = () => {
        this.setState({open: false})
    };

    handleSubmitClick = async () => {
        this.handleClose();
        this.setState({
            submitMessage: <div/>,
            price: document.getElementById('updateDrinkPrice').value
        }, async () => {
            var response = await this.waitForSubmit();
            if (response.status === 200) {
                this.setState({
                    submitMessage: <SubmitSuccess/>,
                })
            }
            if(response === "invalidPrice"){
                this.setState({submitMessage: <SubmitIncomplete invalid={"Price"}/>})
            }
            if(response === "invalidRating"){
                this.setState({submitMessage: <SubmitIncomplete invalid={"Rating"}/>})
            }
            if (response.status === 500) {
                this.setState({
                    submitMessage: <SubmitFailure/>,
                })
            }
        })
    }

    waitForSubmit = async () => {
        var response = await SubmitData("update",this.props.id,this.state.name,this.props.size,this.state.price,this.state.rate)
        return (response)
    }

    render(){
        return(
            <ThemeProvider theme={theme}>
                <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                    <div className='verifyBackground'>
                        <DialogTitle id="form-dialog-title" className='submitTitle'>
                           <p> Would you like to update </p>
                            <p>{this.state.name}?</p>
                        </DialogTitle>
                        <DialogContent>
                            <div className='submit'>
                                <TextField required id="updateDrinkPrice" style={{ width: 115 }}
                                           type="number" variant="outlined" label="Price"
                                           defaultValue={this.props.price}
                                           placeholder={"0.00"} min={0} max={100}
                                           InputProps={{
                                               startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                           }}
                                />
                            </div>
                            <div className='submit'>
                                <Typography>Rating</Typography>
                                <Slider
                                    id="newDrinkRate"
                                    defaultValue={this.props.rate}
                                    aria-labelledby="rating-slider"
                                    step={0.5}
                                    marks={ratings}
                                    valueLabelDisplay="auto"
                                    min={1}
                                    max={10}
                                    onChangeCommitted={(value)=>
                                        this.setState({rate: parseFloat(value.target.textContent)})}
                                />
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <div className='input'>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<CheckIcon/>}
                                    onClick={this.handleSubmitClick}
                                >Submit
                                </Button>
                            </div>
                        </DialogActions>
                    </div>
                </Dialog>
            </ThemeProvider>
        )
    }
}
