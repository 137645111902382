import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {NavLink} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        margin: "auto",
        radius: '10px',
    },
}));

export default function SimpleList(props) {
    const classes = useStyles();
    const infoArray = props.infoArray;
    return (
        <div className={classes.root} style={{borderRadius: "12px",}}>
            <List component="nav" aria-label="My saved bars">
                {infoArray.map((bar) => {
                    return (
                        <ListItem  button key={bar.id}>
                            <NavLink className="sidebarLink" to={"/saved/"+bar.id}>
                                <ListItemText primary={bar.name} primaryTypographyProps={{variant: 'h5'}}/>
                            </NavLink>
                        </ListItem>
                    );
                })}
            </List>
        </div>
    );
}