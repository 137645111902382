import React from "react";
import GetBarDrinks from "./GetBarDrinks";
import CircularProgress from '@material-ui/core/CircularProgress';
import {createMuiTheme} from "@material-ui/core/styles";
import {ThemeProvider} from "@material-ui/core/styles";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#33c0ee'},
        secondary: {
            main: '#17e294',
        },
        text:{
            primary: "#FFFFFF",
            secondary: "#FFFFFF"
        }
    },
    typography: {
        fontSize: 12,
    }
});

class DetailsMenu extends React.Component{
    constructor(props) {
        super(props);
        this.state= {
            barName: '',
            barLocation: '',
            barAddress: '',
            barHours: '',
            url: '',
            priceTier: 0,
            trueRating: 'X',
            fourSquareRating: 0,
            response: [],
            rendered: false,
            fsRate: <p/>,
            details: <div/>
        }
    }

    shouldComponentUpdate(nextProps,nextState,nextContext) {
        if(this.state.rendered){
            return false
        }
        else{
            return true
        }
    }

    componentDidMount() {
        const endPoint = 'https://api.foursquare.com/v2/venues/';
        const params = "?" +
            "client_id=5SHXBDE5CEWM0SWVFVAV0GUCHTSJ2WTWZIDT2Y31KFMS2WMN&" +
            "client_secret=4HIP5Y1V2UZFW5AMB2FZ25X2JAPBQUCHYRJUSOBGB5CVN545&" +
            "v=20180323";
        var fsUrl = endPoint + this.props.barID + params;
        var networkDataReceived = false;

        var networkUpdate = fetch(fsUrl).then((response) => {
            return response;
        }).then((response) => {
            networkDataReceived = true;
            caches.open('temp').then((cache) => {
                const expires = new Date();
                expires.setSeconds(
                    expires.getSeconds() + 12 * 3600,
                );
                const cachedResponseFields = {
                    status: response.status,
                    statusText: response.statusText,
                    headers: { 'SW-Cache-Expires': expires.toUTCString() },
                };
                response.headers.forEach((v, k) => {
                    cachedResponseFields.headers[k] = v;
                });
                return response.blob().then((body) => {
                    cache.put(fsUrl, new Response(body, cachedResponseFields));
                });
            });
            return response.clone();
        }).then((response) => {
            response.json().then((res) => {
                this.renderResponse(res)
            })
        }).catch(error => {
            console.log(error)
        })

        caches.open("Bar Names").then((cache) => {
            caches.match(fsUrl).then((response) => {
                if (!response) throw Error("No data");
                return response.json();
            }).then((data) => {
                if (!networkDataReceived) {
                    this.renderResponse(data);
                }
            }).catch(() => {
                return networkUpdate;
            })
        })

    }

    renderResponse(response){
        var data =response.response.venue;
        this.setState({
            barName: data.name,
            barLocation: data.location.city + ', ' + data.location.state,
            barAddress: data.location.formattedAddress,
            barHours:'',
            url: data.url
        })
        if(data.rating){
            this.setState({fourSquareRating: data.rating})
        }
        if(data.hours){
            this.setState({barHours: data.hours.status})
        }
        if(data.price){
            this.setState({priceTier: data.price.tier})
        }
        if(this.state.fourSquareRating !== 0){
            this.setState({fsRate:
                    <div>
                        <p>FourSquare Rating: {this.state.fourSquareRating}/10</p>
                    </div>
            })
        }

        if(this.state.barName.length > 0){
            this.setState({rendered: true, details:
                    <ThemeProvider theme={theme}>
                        <div className='background'>
                            <div className='menu'>
                                <div className='barHeading'>
                                    <p>
                                        <a className="venuename" href={this.state.url} target="_blank"  rel="noopener  noreferrer">
                                            {this.state.barName}
                                        </a>
                                    </p>
                                    <p>{this.state.barLocation}</p>
                                </div>
                                <div>
                                    <p>{this.state.barHours}</p>
                                </div>
                                {this.state.fsRate}
                                <GetBarDrinks barID={this.props.barID} barName={this.state.barName} barAddress={this.state.barAddress}
                                              foursquareRating={this.state.fourSquareRating} priceTier={this.state.priceTier}
                                              submitted={this.props.submitted}
                                />
                            </div>
                        </div>
                    </ThemeProvider>
            })
        }
    }

    render(){
        return this.state.rendered ? (
            this.state.details
        ) : (
            <ThemeProvider theme={theme}>
                <div className='background'>
                    <div className='loading'>
                        <h1>Loading</h1>
                        <CircularProgress color="secondary" />
                    </div>
                </div>
            </ThemeProvider>
        )


    }

}

export default DetailsMenu