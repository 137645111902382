//Caches selected bar's menu and id when a user marks it as a favorite

import React from "react";
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Button from "@material-ui/core/Button";

export default class FavoriteButton extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            favorite: false,
            button: FavoriteBorderIcon
        }
    }

    componentDidMount() {
        var barID = this.props.barID;
        var url = "https://api.du-pl.com/bars/" + barID;
        caches.open("Bar Menus").then((cache) => {
            caches.match(url).then((response) => {
                if(response !== undefined){
                    this.setState({favorite: true, button: FavoriteIcon})
                }
            })
        })
    }

    handleClick(){
        var barID = this.props.barID;
        var url = "https://api.du-pl.com/bars/" + barID;
        const endPoint = 'https://api.foursquare.com/v2/venues/';
        const params = "?" +
            "client_id=5SHXBDE5CEWM0SWVFVAV0GUCHTSJ2WTWZIDT2Y31KFMS2WMN&" +
            "client_secret=4HIP5Y1V2UZFW5AMB2FZ25X2JAPBQUCHYRJUSOBGB5CVN545&" +
            "v=20180323";
        var fsUrl = endPoint + barID + params;


        if(this.state.favorite) {
            this.setState({favorite: false, button: FavoriteBorderIcon});
            caches.open('Bar Names').then((cache) => {
                console.log("deleting from bar names")
                cache.delete(fsUrl)
            })
            caches.open('Bar Menus').then((cache) => {
                cache.delete(url).then(function() {
                })
            })
        }
        else{
            this.setState({favorite: true, button: FavoriteIcon})

            caches.open("temp").then((cache) =>{
                cache.match(fsUrl).then((response) =>{
                    const cachedResponseFields = {
                        status: response.status,
                        statusText: response.statusText,
                        headers: { },
                    };
                    return response.blob().then((body) => {
                        body.text().then((inner) => {
                            var bodyJson = JSON.parse(inner).response.venue;
                            var barName = bodyJson.name;
                            var barID = bodyJson.id;
                            var barUrl = bodyJson.url;
                            var barCity = bodyJson.location.city;
                            var barState = bodyJson.location.state;
                            var barRating = bodyJson.rating;

                            var newJson = {
                                response: {
                                 venue: {
                                     id: barID, name: barName, url: barUrl, rating: barRating,
                                     location: {
                                         city: barCity, state: barState
                                     }}}}
                            var newBody = JSON.stringify(newJson)
                            var newBlob = new Blob([newBody],{type: "application/json; charset=utf-8"})
                            return newBlob
                        }).then((newBlob) => {
                            caches.open("Bar Names").then((cache) => {
                                cache.put(fsUrl, new Response(newBlob, cachedResponseFields));
                            })
                        })
                    })
                })
            });

            caches.open('Bar Menus').then((cache) => {
                cache.add(url).then(() => {
                })
            })
        }
    }

    render(){
        return(
            <div className="inner">
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<this.state.button/>}
                    onClick={()=>{this.handleClick()}}
                >
                    Save
                </Button>
            </div>
        )
    }
}