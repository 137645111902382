import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import EmailIcon from '@material-ui/icons/Email';
import WorkIcon from '@material-ui/icons/Work';
import Divider from "@material-ui/core/Divider";

export default class AboutPage extends React.Component{

    handleLinkClick = event => {
        window.open('http://patrickvevans.com')
    };

    render(){
        return(

            <div>
                <DialogTitle id="form-dialog-title" className='submitTitle'>
                    <Typography style={{"fontSize": 22}}>
                        About This Site
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <div className="about" color='textSecondary'>
                        <Typography variant="h6"> Built and Maintained</Typography>
                        <Typography variant="h6">by Patrick Evans</Typography>
                        <p/>
                        <Divider/>
                        <p><img className="foursquareAttr" src={require("../img/foursquare.png")} alt='Powered by FourSquare' /></p>
                        <p>Front-End built with ReactJS and JS ES6</p>
                        <p>Back-End Server built with NodeJS, Express and MariaDB</p>
                    </div>
                </DialogContent>
                <DialogActions>
                    <div className='aboutbuttons'>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<EmailIcon/>}
                            href='mailto:patrickvevans@gmail.com'
                        >Contact
                        </Button>
                        <div className='buttonDivide'/>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<WorkIcon/>}
                            onClick={()=> {this.handleLinkClick()}}
                        >Portfolio
                        </Button>
                    </div>
                </DialogActions>
            </div>
        )
    }
}