import axios from "axios";

export default async function SubmitData(method,id,name,size,price,rate){
    if(method === "new"){
        if(name.length <= 0 || name.length > 50){
            return "invalidName"
        }
        if(price <= 0 || price.toString().length > 6){
            return "invalidPrice"
        }
        if(rate < 1 || rate > 10) {
            return "invalidRating"
        }
        var comboName1 = name + ">>" + size;
        if(name.length > 0 && price > 0 && rate > 0){
            const endPoint = 'https://api.du-pl.com/bars/';
            const data = {
                name: comboName1,
                price: price,
                rate: rate
            };

            try {
                var response1 = await axios.put(endPoint + id, data);
                return(response1)
            } catch(err) {
                return (err.response); // TypeError: failed to fetch
            }
        }
        else{
            return "error"
        }
    }
    if(method === "update"){
        console.log(id,name,size,price,rate)
        if(price <= 0 || price.toString().length > 6){
            return "invalidPrice"
        }
        if(rate < 1 || rate > 10) {
            return "invalidRating"
        }
        var comboName2 = name + ">>" + size;
        if(price > 0 && rate > 0){
            const endPoint = 'https://api.du-pl.com/bars/verify/';
            const data = {
                name: comboName2,
                size: size,
                price: price,
                rate: rate
            };

            try {
                var response2 = await axios.put(endPoint + id, data);
                return(response2)
            } catch(err) {
                return (err.response); // TypeError: failed to fetch
            }
        }
        else{
            return "error"
        }
    }
    else {
        return "error"
    }
}