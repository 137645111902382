import React from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import GetRateAverage from "./GetRateAverage";
import ShareBarButton from "./ShareBarButton";
import GetDirections from "./GetDirections";
import MenuTable from "./MenuTable";
import Submission from "../SubmitForm/Submission";
import GetVolume from "./GetVolume";
import FavoriteButton from "./FavoriteButton";

export default class getBarDrinks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            gotData: false,
            data: [],
            rows: [],
            rate: [],
            trueRating: '',
            loading:
                <div>
                    <h1 className='loadingDrinks'>Loading Drinks</h1>
                    <CircularProgress color="secondary"/>
                </div>
        }
    }
    componentDidMount() {
        const barID = this.props.barID;
        const myEndPoint = 'https://api.du-pl.com/bars/';
        const url = myEndPoint + barID;

        var networkDataReceived = false;

        var networkUpdate = fetch(url).then((response) => {
            return response.json();
        }).then((data) => {
            networkDataReceived = true;
            this.updateTable(data);
        }).catch(error => {
            if (!error.response) {
                this.setState({loading:
                        <div>
                            <h1 className='loadingDrinks'>Server Error, Please Try Again Later</h1>
                        </div>})
            } else {
                this.setState({loading:
                        <div>
                            <h1 className='loadingDrinks'>Couldn't Connect to Server</h1>
                        </div>
                });
                console.log("error: ",error.response.data.message);
            }
        })

        caches.open("Bar Menus").then((cache) => {
            caches.match(url).then((response) => {
                if (!response) throw Error("No data");
                return response.json();
            }).then((data) => {
                if (!networkDataReceived) {
                    this.updateTable(data);
                }
            }).catch(() => {
                return networkUpdate;
            })
        })
    }

    updateTable(data) {
        var rows = [];
        this.setState({data: data});
        for (var i = 0; i < this.state.data.length; i++) {
            var splitName = this.state.data[i].name.split(">>");
            var name = splitName[0];
            var sizeLabel = splitName[1];
            var capName = name.substring(0, 1).toUpperCase() + name.substring(1)
            var volume = GetVolume(sizeLabel);
            rows.push({
                name: capName, size: sizeLabel, price: this.state.data[i].price,
                rate: this.state.data[i].rating, count: this.state.data[i].count, volume: volume
            })
            var nextRate = this.state.rate.concat(this.state.data[i].rating);
            this.setState({rate: nextRate})
        }
        this.setState({rows: rows});
        var rateAverage = GetRateAverage(this.state.rate, this.props.foursquareRating, this.props.priceTier,
            this.props.barAddress);
        if (isNaN(rateAverage)) {
            this.setState({trueRating: '--'})
        } else {
            var roundedRate = Math.round(rateAverage * 10) / 10
            this.setState({trueRating: roundedRate})
        }
        this.setState({gotData: true})
    }

    renderMenu() {
        const rows = this.state.rows;

        let renderTable =
            <div className='noDrinks'>
                No drinks entered yet.. Be the first by submitting below!
            </div>;

        if(this.state.data.length){
           renderTable = <MenuTable rows={rows} id={this.props.barID}/>
        }

        return (
            <div className='background'>
                <p className='rating'>DU-PLace Rating: {this.state.trueRating}</p>
                <div className='outer'>
                    <GetDirections barAddress={this.props.barAddress}/>
                    <ShareBarButton barID={this.props.barID}/>
                    <FavoriteButton barID={this.props.barID} barName={this.props.barName} />
                </div>
                <div className='menuheading'>
                    {renderTable}
                </div>
                <Submission barID={this.props.barID}/>
            </div>
        )
    }

    render() {
        return (this.state.gotData ? (this.renderMenu()
            ) : (
                <div className='background'>
                    <div className='menuheading'>
                        {this.state.loading}
                    </div>
                </div>
            )
        )
    }
}