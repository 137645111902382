import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import {createMuiTheme} from "@material-ui/core/styles";
import {ThemeProvider} from "@material-ui/core/styles";
import SubmitVerify from "../SubmitForm/SubmitVerify";
import SubmitFailure from "../SubmitForm/SubmitFailure";

const theme = createMuiTheme({

})

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headCells = [
    {id: 'name', numeric: false, label: 'Name', align: 'left'},
    {id: 'size', numeric: true, label: 'Size', align: 'right'},
    {id: 'price', numeric: true, label: 'Price', align: 'right'},
    {id: 'rate', numeric: true, label: 'Rating', align: 'right'},
    {id: 'count', numeric: true, label: 'Reviews', align: 'right'},
];

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <ThemeProvider theme={theme}>
                <TableRow>
                    {headCells.map(headCell => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </ThemeProvider>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    title: {
        flex: '1 1 100%',
    },
}));

const EnhancedTableToolbar = props => {
    const classes = useToolbarStyles();

    return (
        <Toolbar        >
            <Typography className={classes.title} variant="h5" id="tableTitle">
                Drinks Menu
            </Typography>
        </Toolbar>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {
        color: "black",
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));


export default function MenuTable (props) {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('names');
    const [verify, setVerify] = React.useState(false);
    const [name, setName] = React.useState('');
    const [rate, setRate] = React.useState('');
    const [size, setSize] = React.useState('');

    const classes = useStyles();
    const rows = props.rows;

    const handleRequestSort = (event, property) => {
        console.log(property)
        if(property === "size"){
            property = "volume";
        }
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleClick = async (event, row) => {
        console.log(row)
        setName(row.name);
        setSize(row.size);
        setRate(row.rate);
        if(verify === true){
            setVerify(false)
        }
        if(verify === false) {
            setVerify(true)
        }
        console.log(verify)
    }

    let submitPopup;
    if(verify){
        submitPopup = <SubmitVerify open={true} name={name} rate={rate} size={size} id={props.id}/>
    }
    else{
        submitPopup = <div/>
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar />
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <ThemeProvider theme={theme}>
                            <TableBody>
                                {stableSort(rows, getSorting(order, orderBy)).map(row => {
                                    return (
                                        <TableRow key={row.name + row.size} className="rows"
                                                  onClick={event => handleClick(event, row)}>
                                            <TableCell component="th" scope="row">
                                                {row.name}
                                            </TableCell>
                                            <TableCell align="right">{row.size}</TableCell>
                                            <TableCell align="right">{Number(row.price).toFixed(2)}</TableCell>
                                            <TableCell align="right">{Number(row.rate).toFixed(2)}</TableCell>
                                            <TableCell align="right">{Number(row.count)}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </ThemeProvider>
                    </Table>
                </TableContainer>
            </Paper>
            {submitPopup}
        </div>
    );
}
