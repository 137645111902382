//List of Possible Drink Names

export default function DrinkAutocomplete(){
    const drinkNames = [

    ]

    //'Carlsberg',
    //         'Heineken'

    return (drinkNames);
}