//Used to calculate volume in ounces based off size name of drink

export default function GetVolume(size){
    var ounces;
    switch (size){
        case "Shot":
            ounces = 1.5;
            break;
        case "Double":
            ounces = 3.0;
            break;
        case "Bottle":
            ounces = 12;
            break;
        case "Can":
            ounces = 12;
            break;
        case "Pint":
            ounces = 16;
            break;
        case "Liter":
            ounces = 32;
            break;
        case "Cocktail":
            ounces = 8;
            break;
        default:
            ounces = 12;
    }
    return(ounces)
}